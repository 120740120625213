import React from 'react'
import CancelIcon from '@material-ui/icons/Cancel';
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import Img from "gatsby-image/withIEPolyfill"

const colorAliceBlue ='#F0F8FF' 

const styles = {
  image: (im) => ({
    height:96, 
    width:96, 
    objectFit:'cover',
    opacity:im.delete?0.3:1.0,
    border:im.open?'4px solid #DDD':null,
    borderRadius:im.open?96:0,
    transform: im.rotate?'rotate(' + im.rotate + 'deg)':null,
    filter:im.open?'blur(1px)':null,
    transition:im.rotate||im.open?'2000ms all ease':null,
  }),
  deleteButton: (del)=>({
    position:'relative',
    color:del?'red':'#BBB',
  }),
  rotateButton: (rot)=>({
    color:rot?'red':'#BBB',
  }),
}

const text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."

export default ({list, index, setList, edit}) => {  
  const image = list[index];
  const rotate = list[index].rotate;
  const handleOpen = () => setList(list.map((it, ix)=> ({...it, open:ix===index})))
  const handleToggleFld = (fld) => setList([...list.slice(0, index), {...list[index], [fld]:list[index][fld]?undefined:true}, ...list.slice(index + 1)])
  const handleRotate = () => setList([...list.slice(0, index), {...list[index], rotate:rotate?rotate===-270?undefined:list[index].rotate-90:-90}, ...list.slice(index + 1)])
  let style = image?styles.image(image):{}
  return (
    <div>
      <figure className="image is-96x96" onClick={handleOpen}>
        {image?<img
          style={style}
          src = {`${image.thumbSrc?image.thumbSrc:image.src}?${image.mdate}`} 
          alt={image.thumbSrc?image.thumbSrc:image.src}         />
        :null}  
      </figure>  
      {edit?
        <figcaption>
          <CancelIcon 
            style={styles.deleteButton(image?image.delete?true:undefined:undefined)} 
            onClick={()=>handleToggleFld('delete')}
          />
          <RotateLeftIcon 
            style={styles.rotateButton(image?image.rotate?image.rotate:0:0)} 
            onClick={handleRotate}
          />
        </figcaption>
      :null}  
    </div>
  )
}

