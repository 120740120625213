import React, {useState}  from 'react'
import { connect } from 'react-redux';
import { setNavbarKey, NAVBAR_KEY_SUBDIR} from '../../state/navbar';


const butColor ='#F0F8FF' 

const Function = ({navbar, subdir, dispatch}) => {


    const handleGoSubdir = (sub) => {
        console.log('navbar, sub:', sub)
        dispatch(setNavbarKey('subdir', subdir?subdir + '/' + sub:sub))
    }
    const handleGoBack = () => {
        const newSubdir = subdir?subdir.lastIndexOf('/')!==-1?subdir.substr(0, subdir.lastIndexOf('/')):undefined:undefined
        dispatch(setNavbarKey(NAVBAR_KEY_SUBDIR, newSubdir))
    }

    return (
        <div className='buttons'>
            {navbar?navbar.map(di => 
                    <button key={di} className="button is-dark is-small is-outlined" onClick={()=>handleGoSubdir(di)}>{di}</button>
            ):null}
            {subdir?<button className="button is-dark is-small is-outlined" onClick={()=>handleGoBack()}>Go back from {subdir}</button>:null}
        </div>
    )  
}   

const mapStateToProps = state => ({
    subdir:state.navbar[NAVBAR_KEY_SUBDIR],
})
  
export default connect(mapStateToProps, null)(Function)


